<!--
 * @Author: hewj hewj@cloudcc.com
 * @Date: 2022-08-30 14:34:53
 * @LastEditors: hewj hewj@cloudcc.com
 * @LastEditTime: 2022-08-31 15:49:46
 * @FilePath: \lightning-web\src\views\account\views\login\component\validationMFA.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- <el-dialog
    :visible.sync="MFADialogFormVisible"
    :show-close="false"
    width="max-content"
  > -->
    <div class="validationMFA" v-if="MFADialogFormVisible">
      <div class="leftTips">
        <ul>
          <li>
            <!-- 您必须先在智能设备上安装一个MFA应用程序，才能继续进行操作． -->
            {{ $t('c2225') }}
          </li>
          <li>
            <!-- 在智能设备上支持MFA的常用应用程序有洋葱．Google Authenticator. -->
            {{ $t('c2226') }}
          </li>
          <li>
            <!-- 如果您因为某些原因（如手机丢失或者误解除MFA应用等）无法进行验证，可以通过管理员解除MFA绑定. -->
            {{ $t('c2227') }}
          </li>
        </ul>
        <!-- 二维码 -->
        <!-- <img src="" alt=""> -->
      </div>
      <div class="rightBox">
        <div class="security_code_box">
          <div>
            <!-- 验证虚拟MFA设备 -->
            {{ $t('label.login.verification.mfa.device') }}
          </div>
          <el-form label-position="top" :model="formData" class="demo-form-inline">
            <!-- "请输入安全码:" -->
            <el-form-item :label="$t('label.login.input.verification.code')">
              <!-- "请输入6位安全码" -->
              <el-input
                v-model="formData.verificationCode"
                :placeholder="$t('c2229')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btns">
            <el-button @click="cancel">
              <!-- 取消 -->
              {{ $t('label.emailtocloudcc.button.back') }}
            </el-button>
            <!-- <el-button @click="submitValidation">提交验证</el-button> -->
            <el-button class="confirm" @click="submitValidation">
              <!-- 提交验证 -->
              {{ $t('label.login.button.verification') }}
            </el-button>
          </div>
       </div>
      </div>
    </div>
  <!-- </el-dialog> -->
</template>

<script>
import * as loginApi from "../api";
export default {
  name: "validationMFA",
  data() {
    return {
      formData: {
        // MFA验证码
        verificationCode: "",
      },
      MFADialogFormVisible: false,
    };
  },
  methods: {
    cancel(){
      this.MFADialogFormVisible=false ;
      this.$emit('changeKey',"cancel")
    },
    // 打开弹框
    open(res) {
      this.resData = res;
      this.MFADialogFormVisible = true;
      this.$emit('changeKey',"op")
    },
    // MFA验证码验证
    submitValidation() {
      let params = {
        verificationCode: this.formData.verificationCode,
        mfaRandomKey: this.resData.data.data.mfaRandomKey,
        mfaRandom: this.resData.data.data.mfaRandom,
        binding: this.resData.data.data.binding,
        organizationId:this.resData.data.data.organizationId
      };
      // MFA验证码验证
      loginApi
        .mfaVerification(params)
        .then((res) => {
          if (res.data.result) {
            // 不需要关闭dialog直接跳转
            // this.MFADialogFormVisible = false;
            let href = "./#/?binding=" + res.data.data.accessToken;
            window.location.replace(href);
          } else {
            this.$message.info(res.data.returnInfo);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
//去掉自带padding
::v-deep .el-dialog__body {
    padding: 0;
}
//el-dialog去掉header 
::v-deep  .el-dialog__header {
    padding: 0; 
    border-bottom: none;
}
.validationMFA{
  background: #fff;
  display: flex;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.12);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  height: 648px;
  .leftTips{
    padding: 88px 0;
    width: 428px;
    display: flex;
    border-right: 1px solid #E1E1E1;
    flex-direction: column;
    align-items: center;
    font-size: 0;
    ul{
      width: 330px;
      li{
        list-style: disc;
        margin-bottom: 20px;

        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }
    }
    img{
      width: 228px;
      height: 228px;
      margin-top: 60px;
    }

  }
  .rightBox{
    padding: 88px 0;
    width: 699px;
    display: flex;
    justify-content: center;
    .security_code_box{
      
      width: 424px;
      height: max-content;
      //调节表单item间隔距离
      ::v-deep .el-form-item__label{
        line-height: 0;
      }
      &>div{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 48px;
      }
      .btns{
        .confirm{
          background: #006DCC;
          color: #fff;
        }
      }

  }
  }


}
@media screen and (max-width: 768px) {
  .validationMFA {
    box-shadow: none;
    border-radius: none;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 100%;
    .leftTips{
      border-right: none;
      width: 90%;
    }
    .rightBox{
      display: flex;
      justify-content: center;
      width: 90%;
      padding: 0;
    }    
  }
}
</style>
