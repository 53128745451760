<template>
  <!--    未绑定MFA-->
  <!-- <el-dialog
    :visible.sync="MFADialogFormVisible"
    :show-close="false"
    width="max-content"
  > -->
    <div class="enable" v-if="MFADialogFormVisible">
      <div class="leftTips">
        <ul>
          <li>
            <!-- 您必须先在智能设备上安装一个MFA应用程序，才能继续进行操作． -->
            {{ $t('c2225') }}
          </li>
          <li>
            <!-- 在智能设备上支持MFA的常用应用程序有洋葱．Google Authenticator. -->
            {{ $t('c2226') }}
          </li>
          <li>
            <!-- 如果您因为某些原因（如手机丢失或者误解除MFA应用等）无法进行验证，可以通过管理员解除MFA绑定. -->
            {{ $t('c2227') }}
          </li>
          <div>
            <!-- 请注意： -->
            {{ $t('label.login.verification.please.note') }}
          </div>
          <p>
            <!-- 如果您的账号已经被很多人共享使用，那么当您成功绑定MFA之后，其他未绑定MFA的人将无法登录。解决方法是让其他人也安装MFA应用程序并扫描此页的二维码，
            或者保存此二维码图片供其他人后续进行扫码。但从安全最佳实践来看，我们建议您取消多人共享账号. -->
            {{ $t('c2228') }}
          </p>
        </ul>
        <div class="qr_code_box">
          <div>
            <!-- 扫码获取： -->
          {{ $t('label.login.verification.scan.code.acquisition') }}
          </div>

          <div class="qr_code">
            <div class="qr_code_img">
              <img :src="qrCodeImg" />
            </div>
            <div>
              <!-- 请使用智能手机上的MFA应用程序进行扫码 -->
              {{ $t('label.login.verification.please.scan') }}
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="security_code">
            <div class="title">
              <!-- 启用虚拟MFA设备 -->
             {{ $t('label.login.bound.mfadevice.title') }} 
            </div>
            <div>
              <!-- 请输入您从MFA应用程序中获取的安全码 -->
              {{ $t('label.login.verification.please.enter') }}
            </div>
            <!-- "请输入6位安全码" -->
            <el-input
              v-model="verificationCode"
              :placeholder="$t('c2229')"
              class="inputCode"
            ></el-input>
            <div>
              <el-button @click="cancel">
                <!-- 取消 -->
                {{ $t('label.emailtocloudcc.button.back') }}
              </el-button>
              <el-button class="confirm" @click="comfirmEnable">
                <!-- 确认启用 -->
                {{ $t('label.login.verification.confirmation.enabled') }}
              </el-button>
            </div>
        </div>
      </div>
    </div>
  <!-- </el-dialog> -->
</template>

<script>
import * as loginApi from "../api";
export default {
  name: "MFA",
  data() {
    return {
      MFADialogFormVisible: false,
      // 二维码图片
      qrCodeImg: "",
      // MFA验证码
      verificationCode: "",
      // 登录接口返回的数据
      resData: {},
    };
  },
  methods: {
    cancel(){
      this.MFADialogFormVisible=false ;
      this.$emit('changeKey',"cancel")
    },
    // 打开弹框
    open(res) {
      this.resData = res;
      this.MFADialogFormVisible = true;
      this.$emit('changeKey',"op")
      // 获取二维码图片
      let params = {
        code: res.data.data.qrCode, // 登录接口返回的qrCode
        multipleCode: "true", // 传"true"就行
        rSize: 650, // 传650就行，图片的宽和高
      };
      loginApi
        .getMFAQrCodeImg(params)
        .then((res) => {
          // 这里的第一次处理后台返回来的二进制留数据 转化为base64
          return (
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, key) => data + String.fromCharCode(key),
                ""
              )
            )
          );
        })
        .then((data) => {
          // 这一次箭头函数是依赖于第一次.then函数处理的data值
          this.qrCodeImg = data;
        })
        .catch(() => {});
    },
    // 确认启用
    comfirmEnable() {
      let params = {
        verificationCode: this.verificationCode,
        mfaRandomKey: this.resData.data.data.mfaRandomKey,
        mfaRandom: this.resData.data.data.mfaRandom,
        binding: this.resData.data.data.binding,
        organizationId:this.resData.data.data.organizationId
      };
      // MFA验证码验证
      loginApi
        .mfaVerification(params)
        .then((res) => {
          if (res.data.result) {
            // 不需要关闭dialog直接跳转
            // this.MFADialogFormVisible = false;
            let href = "./#/?binding=" + res.data.data.accessToken;
            window.location.replace(href);
          } else {
            this.$message.info(res.data.returnInfo);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
//去掉自带padding
::v-deep .el-dialog__body {
    padding: 0;
}
//el-dialog去掉header 
::v-deep  .el-dialog__header {
    padding: 0; 
    border-bottom: none;
}
.enable {
  background: #fff;
  font-size: 14px;
  display: flex;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.12);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  // height: 648px;
  .leftTips{
    padding: 50px 0;
    width: 428px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #E1E1E1;
    ul{
      width: 360px;
      li{
        list-style: disc;
        margin-bottom: 20px;

        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }
    }
    .qr_code_box {
      width: 360px;
      margin-top: 30px;
    // display: flex;
    .qr_code {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qr_code_img {
        width: 200px;
        height: 200px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

  }
  }
  .rightBox{
    padding: 50px 0;
    width: 699px;
    display: flex;
    justify-content: center;
    .security_code {
      width: 424px;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 48px;
      }
      .inputCode{
        margin: 5px 0 30px 0;
      }
      .confirm{
        background: #006DCC;
        color: #fff;
      }
    }
  }    

}
@media screen and (max-width: 768px) {
  .enable {
    box-shadow: none;
    border-radius: none;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 100%;
    .leftTips{
      border-right: none;
      width: 90%;
    }
    .rightBox{
      display: flex;
      justify-content: center;
      width: 90%;
    }    
  }
}
</style>
