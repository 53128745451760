// 获取浏览器名称
export function getBrowser() {
  var UserAgent = navigator.userAgent.toLowerCase()
  var browser = null
  var browserArray = {
    IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
    Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1, // Chrome浏览器
    Firefox: UserAgent.indexOf('firefox') > -1, // 火狐浏览器
    Opera: UserAgent.indexOf('opera') > -1, // Opera浏览器
    Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1, // safari浏览器
    Edge: UserAgent.indexOf('edge') > -1, // Edge浏览器
    QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
    WeixinBrowser: /MicroMessenger/i.test(UserAgent) // 微信浏览器
  }
  for (var i in browserArray) {
    if (browserArray[i]) {
      browser = i
    }
  }
  return browser
}
// 获取浏览器版本
export function Browse() {
  var browser = {}
  var userAgent = navigator.userAgent.toLowerCase()
  var s;
  // eslint-disable-next-line no-cond-assign
  (s = userAgent.match(/msie ([\d.]+)/)) ? browser.ie = s[1] : (s = userAgent.match(/firefox\/([\d.]+)/)) ? browser.firefox = s[1] : (s = userAgent.match(/chrome\/([\d.]+)/)) ? browser.chrome = s[1] : (s = userAgent.match(/opera.([\d.]+)/)) ? browser.opera = s[1] : (s = userAgent.match(/version\/([\d.]+).*safari/)) ? browser.safari = s[1] : 0
  var version = ''
  if (browser.ie) {
    version = 'IE ' + browser.ie
  } else {
    if (browser.firefox) {
      version = browser.firefox
    } else {
      if (browser.chrome) {
        version = browser.chrome
      } else {
        if (browser.opera) {
          version = browser.opera
        } else {
          if (browser.safari) {
            version = browser.safari
          } else {
            version = '未知浏览器'
          }
        }
      }
    }
  }
  return version
}
// 获取操作系统
export function getOperationSys() {
  var OS = ''
  var OSArray = {}
  var UserAgent = navigator.userAgent.toLowerCase()
  OSArray.Windows = (navigator.platform == 'Win32') || (navigator.platform == 'Windows')
  OSArray.Mac = (navigator.platform == 'Mac68K') || (navigator.platform == 'MacPPC') ||
    (navigator.platform == 'Macintosh') || (navigator.platform == 'MacIntel')
  OSArray.iphone = UserAgent.indexOf('iPhone') > -1
  OSArray.ipod = UserAgent.indexOf('iPod') > -1
  OSArray.ipad = UserAgent.indexOf('iPad') > -1
  OSArray.Android = UserAgent.indexOf('Android') > -1
  for (var i in OSArray) {
    if (OSArray[i]) {
      OS = i
    }
  }
  return OS
}

