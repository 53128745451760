<template>
  <!-- 重置密码 弹框
      :top="top"
  -->
  <div>
    <el-dialog
      :title="$t('label.account.user.resetpw')"
      :visible.sync="isDialogFormVisible"
      :before-close="handleClose"
      top="0"
      :class="{ top1: top, top2: !top }"
    >
      <!--第一层dialog  输入账号 -->
      <!-- 账号  -->
      <div v-if="isShowAccount" class="account">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="" class="formItem">
            <el-col :span="24">
              <el-form-item label="" prop="name">
                <el-input
                  v-model="form.name"
                  :placeholder="$t('label.account.accountNum')"
                />
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
        <div class="footer">
          <div />
          <div class="df">
            <el-button @click="handleClose" class="mr10">
              <!-- 想起来了 -->
              {{ $t("label.account.remember") }}
            </el-button>
            <!-- type="primary" -->
            <div
              class="button-loading el-button"
              :class="{ animate: resetFlag1 }"
              @click="submitForm('form', '1')"
            >
              <!-- 重置 -->
              {{ $t("label.account.Reset") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 第二层dialog  输入邮箱验证码  密码  -->
      <!-- 如果是账号--》邮件状态 -->
      <div v-if="!isShowAccount" class="change">
        <!-- --------------------------------------------- -->
        <!-- 账号修改 -->
        <el-form ref="form" :model="form" :rules="rules">
          <div class="formDiv">
            <!--用户名-->
            <el-form-item prop="name" class="formItem">
              <el-input
                v-model.trim="form.name"
                :placeholder="$t('label.account.accountNum')"
                disabled
              />
            </el-form-item>
            <!-- 验证码-->
            <el-form-item prop="code" class="formItem">
              <el-input
                v-model.trim="form.code"
                :placeholder="$t('label.account.label.captcha')"
                autocomplete="new-password"
                :validate-event="false"
              />
              <img
                v-show="form.code.length > 0"
                class="delete1Btn"
                src="../img/delete-icon.svg"
                alt=""
                @click="form.code = ''"
              />
              <!-- <img :src="imgSrc" alt="" class="tick" @click="displayPW" /> -->
              <div v-show="form.code.length < 1" class="countDown">
                <span v-if="isShowObtain" class="obtain1" @click="obtain">
                  <!-- 获取 -->
                  {{ $t("label.account.obtain") }}
                </span>
                <span v-else>{{ count }}s</span>
              </div>
              <div v-if="isShowInvalid" class="invalid">
                <!-- 15分钟后 -->
                <!-- 验证码失效 -->
                {{ $t("label.account.codeFailure") }}
              </div>
            </el-form-item>
            <!--新密码-->
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                type="password"
                :placeholder="$t('label.account.NewPassword')"
                :onkeyup="(form.password = form.password.replace(/\s+/g, ''))"
                autocomplete="new-password"
              />
            </el-form-item>
            <!--再次输入新密码-->
            <el-form-item prop="checkPass">
              <el-input
                v-model="form.checkPass"
                type="password"
                :placeholder="$t('label.account.Confirm.password')"
                :onkeyup="(form.checkPass = form.checkPass.replace(/\s+/g, ''))"
                autocomplete="new-password"
              />
            </el-form-item>
            <!--密码策略-->
            <span>
              <!--密码复杂性为0时，没有复杂性规则说明  label.account.pswComplexIsZero&ndash;&gt;-->
              <!--密码复杂性不为0时，有复杂性规则说明  label.account.pswComplexReqRules-->
              *
              {{
                pswComplexNum == "0"
                  ? $t("label.account.pswComplexIsZero", {
                      0: minPswLength,
                    })
                  : $t("label.account.pswComplexReqRules", {
                      0: minPswLength,
                      1: pswComplexReqRules,
                    })
              }}
            </span>
          </div>
        </el-form>
        <div class="footer">
          <div />
          <div class="df">
            <el-button @click="previousStep" class="mr10">
              <!-- 返回上一步 -->
              {{ $t("label.account.Back") }}
            </el-button>
            <div
              class="button-loading el-button"
              :class="{ animate: resetFlag2 }"
              @click="reset2"
            >
              <!-- 重置 -->
              {{ $t("label.account.Reset") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
 /*
  重置密码(私有云用账号进行重置) 逻辑如下：
  第一个弹框
  点击重置  获取密码策略后到第二个弹框
  第二个弹框
  点击获取   发送邮件验证码用于修改密码（会向此账号绑定的邮箱里发送验证码）
  点击重置   后端验证输入的验证码是否是邮件里的验证码   验证通过修改密码
  */
import * as loginApi from "../api";
import md5 from "js-md5";
import * as obtain from "@/utils/obtain";
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var validateArea = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      } else {
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      } else {
        // 有区号 需要验证当前手机号格式是否符合区号
        if (this.isShowArea) {
          // 不同前缀不同正则验证规则
          let phoneRe;
          if (this.form.Area == "+86") {
            // 中国+86
            phoneRe = /^(\+?0?86-?)?1[345789]\d{9}$/;
          } else if (this.form.Area == "+852") {
            // 中国香港+852
            phoneRe = /^(\+?852-?)?[569]\d{3}-?\d{4}$/;
          } else if (this.form.Area == "+886") {
            // 中国台湾+886
            phoneRe = /^(\+?886-?|0)?9\d{8}$/;
          } else if (this.form.Area == "+853") {
            // 中国澳门+853    // /^(853)?6\d{7}$/
            phoneRe = /^(\+?853-?)?6\d{7}$/;
          } else if (this.form.Area == "+1") {
            // 美国+1
            phoneRe = /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/;
          } else if (this.form.Area == "+81") {
            // 日本+81
            phoneRe = /^(\+?81|0)\d{1,4}[ -]?\d{1,4}[ -]?\d{4}$/;
          } else if (this.form.Area == "+49") {
            // 德国+349
            phoneRe =
              /^(\+?49[ .-])?0?[19][1567]\d{1,2}(?:[ /-]*\d){7,8}(?!\d)/;
          } else if (this.form.Area == "+7") {
            // 俄罗斯+7
            phoneRe = /^(\+?7|8)?9\d{9}$/;
          } else if (this.form.Area == "+44") {
            // 英国+44
            phoneRe = /^(\+?44|0)7\d{9}$/;
          } else if (this.form.Area == "+33") {
            // 法国+33
            phoneRe = /^(\+?33|0)[67]\d{8}$/;
          } else if (this.form.Area == "+39") {
            // 意大利+39
            phoneRe = /^(\+?39)?\s?3\d{2} ?\d{6,7}$/;
          } else if (this.form.Area == "+34") {
            // 西班牙+34
            phoneRe = /^(\+?34)?(6\d{1}|7[1234])\d{7}$/;
          } else if (this.form.Area == "+48") {
            // 波兰+48
            phoneRe = /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/;
          } else if (this.form.Area == "+55") {
            // 巴西+55
            phoneRe = /^(\+?55|0)-?[1-9]{2}-?[2-9]{1}\d{3,4}-?\d{4}$/;
          }
          if (!phoneRe.test(value)) {
            callback(new Error(this.$t("label.account.Incorrect.format")));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      }
      if (this.resultInfo) {
        callback(new Error(this.resultInfo));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      } else {
        callback();
      }
    };
    var validatePasswprd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      } else if (value.length < parseInt(this.minPswLength)) {
        callback(
          new Error(this.$t("label.account.MinLength") + this.minPswLength)
        );
      } else if (!this.pswComplexReq.test(value)) {
        callback(new Error(this.pswComplexReqRules));
      } else {
        callback();
      }
    };
    var validateChekPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      }
      if (this.form.password != this.form.checkPass) {
        callback(new Error(this.$t("label.account.pw.error.text")));
      } else {
        callback();
      }
    };
    return {
      resetFlag1: false,
      resetFlag2: false,
      top: true, // 弹框距顶部高度  第一个小弹框true 之后大弹框false
      REGION: "", //区分中国站还是国际站
      explorer: "", // 浏览器名称及版本
      operatingSystem: "", // 操作系统名称
      resultInfo: "", // 提示
      minPswLength: "", // 密码最小长度
      pswComplexReq: /./, // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号 //正则
      pswComplexReqRules: "", // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号
      pswComplexNum: 0, // 密码复杂度数字标识
      isShowAccount: true,
      changePass: false,
      isDialogFormVisible: this.dialogFormVisible,
      isShowObtain: true, // 显示获取按钮
      isShowInvalid: false, // 显示失效提示
      form: {
        Area: "", // 手机号前缀
        name: "", // 账号或密码
        code: "", // 验证码
        phone: "", // 手机号
        password: "", // 新密码
        checkPass: "", // 二次输入新密码
      },
      rules: {
        Area: [{ validator: validateArea, trigger: "blur" }],
        name: [{ validator: validateName, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        password: [{ validator: validatePasswprd, trigger: "blur" }],
        checkPass: [{ validator: validateChekPass, trigger: "blur" }],
      },
      count: 60,
      // 号码前缀
      prefixOptions: [
        //   中国香港+852、中国澳门+853、中国台湾+886、美国+1、日本+81、德国+349、
        // 俄罗斯+7、英国+44、法国+33、意大利+39、西班牙+34、荷兰+31、波兰+48、巴西+55
        {
          value: "label.account.China.Mainland",
          label: "+86",
          country: "label.account.China.Mainland",
        },

        {
          value: "label.account.Macao",
          label: "+853",
          country: "label.account.Macao",
        },
        {
          value: "label.account.HongKong",
          label: "+852",
          country: "label.account.HongKong",
        },
        {
          value: "label.account.Taiwan",
          label: "+886",
          country: "label.account.Taiwan",
        },
        {
          value: "label.account.USA",
          label: "+1",
          country: "label.account.USA",
        },
        {
          value: "label.account.jp",
          label: "+81",
          country: "label.account.jp",
        },
        {
          value: "label.account.Germany",
          label: "+49",
          country: "label.account.Germany",
        },
        {
          value: "label.account.Russia",
          label: "+7",
          country: "label.account.Russia",
        },
        {
          value: "label.account.britain",
          label: "+44",
          country: "label.account.britain",
        },
        {
          value: "label.account.France",
          label: "+33",
          country: "label.account.France",
        },
        {
          value: "label.account.Italy",
          label: "+39",
          country: "label.account.Italy",
        },
        {
          value: "label.account.Spain",
          label: "+34",
          country: "label.account.Spain",
        },
        {
          value: "label.account.Netherlands",
          label: "+31",
          country: "label.account.Netherlands",
        },
        {
          value: "label.account.Poland",
          label: "+48",
          country: "label.account.Poland",
        },
        {
          value: "label.account.Brazil",
          label: "+55",
          country: "label.account.Brazil",
        },
      ],
      isShowArea: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        // 判断是输入的账号还是手机号
        var numReg = /^[0-9]*$/;
        var numRe = new RegExp(numReg);
        // 判断：输入全数字为手机号
        if (this.REGION === "cn") {
          if (numRe.test(this.form.name)) {
            // 手机号
            this.isShowArea = false;
          } else {
            // 账号
            this.isShowArea = false;
          }
        } else {
          // 国外只支持账号找回，隐藏手机号找回逻辑
          this.isShowArea = false;
        }
        if (!this.form.name) {
          this.isShowArea = false;
        }
        //
        if (!this.form.code) {
          // this.resetFields();
          this.resultInfo = "";
        }
      },
    },
  },
  created() {
    this.REGION = "com";
    this.explorer = obtain.getBrowser() + obtain.Browse(); // 浏览器名称及版本
    this.operatingSystem = obtain.getOperationSys(); // 操作系统名称
    if (this.REGION === "cn") {
      this.form.Area = "+86";
    } else {
      this.form.Area = "+1";
    }
  },
  methods: {
    // 切换区号校验手机号
    changeArea() {
      this.$refs.form.validateField("name");
    },
    // 获取密码策略
    getPasswordPolicy() {
      if (this.resetFlag1) {
        return;
      }
      this.resetFlag1 = true;
      const parameter = {
        lang: this.$i18n.locale,
        loginName: this.form.name
      };
      loginApi
        .getPasswordPolicy(parameter)
        .then((res) => {
          if (res.data.data.result == "true") {
            this.resetFlag1 = false;
            // 判断是输入的账号还是手机号 显示不同
            if (this.isShowArea) {
              // 手机号
              this.isShowAccount = false;
              this.changePass = true;
              this.top = false;
            } else {
              // 账号
              this.isShowAccount = false;
              this.changePass = false;
              this.top = false;
            }
            // 密码最小长度
            this.minPswLength = res.data.data.minPswLength;
            // 密码复杂度 数字标识
            this.pswComplexNum = res.data.data.pswComplexReq;
            // this.minPswLength = "5";
            // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号
            if (res.data.data.pswComplexReq == "0") {
              this.pswComplexReq = /./;
            } else if (res.data.data.pswComplexReq == "1") {
              this.pswComplexReq = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{0,}$/;
              this.pswComplexReqRules = this.$t("label.account.Alphanumeric");
            } else if (res.data.data.pswComplexReq == "2") {
              this.pswComplexReq =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,}$/;
              this.pswComplexReqRules = this.$t(
                "label.account.UpperLowerlAphanumeric"
              );
            } else if (res.data.data.pswComplexReq == "3") {
              this.pswComplexReq =
                /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{0,}/;
              this.pswComplexReqRules = this.$t(
                "label.account.UpperLowerlAphanumeric.character"
              );
            }
          } else {
            this.resetFlag1 = false;
            this.$message({
              showClose: true,
              message: this.$t("label.account.no.user.info"),
              type: "error",
              duration: 6000,
              // center: true,//居中
            });
          }
        })
        .catch(() => {
          this.resetFlag1 = false;
        });
    },

    // 向手机发送验证码，用于修改密码
    sendMessageForChangePassword() {
      const parameter = {
        mobile: this.form.name,
        lang: this.$i18n.locale,
        countryCode: this.form.Area,
      };
      loginApi.sendMessageForChangePassword(parameter).then(() => {});
    },
    // 根据手机号和验证码判断是否可以修改密码
    isChangePasswordByVerificationCode() {
      if (this.resetFlag2) {
        return;
      }
      this.resetFlag2 = true;
      const parameter = {
        mobile: this.form.name,
        verificationCode: this.form.code,
        lang: this.$i18n.locale,
      };
      loginApi
        .isChangePasswordByVerificationCode(parameter)
        .then((res) => {
          if (res.data.data.result == "true") {
            this.resetFlag2 = false;
            // 修改密码
            // this.userPwModify(res.data.data.verificationCodePass);

            // 多验证一次，再修改
            this.submitForm("form", "3", res.data.data.verificationCodePass);
          } else {
            this.resetFlag2 = false;
            this.resultInfo = res.data.data.returnInfo;
            this.submitForm("form", "2");
            // this.$message({
            //   showClose: true,
            //   message: res.data.data.returnInfo,
            //   type: "error",
            //   duration: 6000,
            //   // center: true,//居中
            // });
          }
        })
        .catch(() => {
          this.resetFlag2 = false;
        });
    },
    // 发送邮件，用于修改密码
    sendEmailForChangePassword() {
      const parameter = {
        loginName: this.form.name,
        lang: this.$i18n.locale,
      };
      loginApi.sendEmailForChangePassword(parameter).then((res) => {
        if (res.data.data.result == "true") {
          // 成功
          this.$message({
            showClose: true,
            message: this.$t("label.account.succSend"),
            type: "success",
            duration: 6000,
            // center: true,//居中
          });
        } else {
          // 失败
          this.$message({
            showClose: true,
            message: this.$t("label.account.failsend"),
            type: "error",
            duration: 6000,
            // center: true,//居中
          });
        }
      });
    },
    // 根据邮件里的验证码判断是否可以修改密码
    isChangePasswordByEmail() {
      if (this.resetFlag2) {
        return;
      }
      this.resetFlag2 = true;
      const parameter = {
        loginName: this.form.name,
        verificationCode: this.form.code,
        lang: this.$i18n.locale,
      };
      loginApi
        .isChangePasswordByEmail(parameter)
        .then((res) => {
          if (res.data.data.result == "true") {
            this.resetFlag2 = false;
            // 修改密码
            // this.userPwModify(res.data.data.verificationCodePass);

            // 多验证一次，再修改
            this.submitForm("form", "3", res.data.data.verificationCodePass);
          } else {
            this.resetFlag2 = false;
            this.resultInfo = res.data.data.returnInfo;
            this.submitForm("form", "2");

            // this.$message({
            //   showClose: true,
            //   message: res.data.data.returnInfo,
            //   type: "error",
            //   duration: 6000,
            //   // center: true,//居中
            // });
          }
        })
        .catch(() => {
          this.resetFlag2 = false;
        });
    },

    // 修改密码
    //verificationCodePass  这是上一步验证码通过后返回的通过码
    userPwModify(verificationCodePass) {
      if (this.resetFlag1) {
        return;
      }
      this.resetFlag1 = true;
      this.newPw = md5(this.form.password);
      const parameter = {
        loginName: this.form.name,
        // 通过码
        verificationCodePass: verificationCodePass,
        // 用户输入的验证码
        verificationCode: this.form.code,
        newPw: this.newPw,
        noCheckOldPw: "1",
      };
      loginApi
        .userPwModify(parameter)
        .then((res) => {
          if (res.data.data.result == "true") {
            this.resetFlag1 = false;
            // 跳转   后端说后端不好返，而且这样写的话 代码比较混乱，改密码就是应该只改密码。。。  所以只能把参数带到登录页登陆
            let parameter1;
            // 账号密码登录  到登录页需要的参数
            parameter1 = {
              pwd: this.form.password,
              username: this.form.name,
              explorer: this.explorer,
              operatingSystem: this.operatingSystem,
              randomCode: "U8e3DsfH56cP",
            };
            this.$emit("resetLogin", parameter1);
            // 修改成功关闭弹框
            this.handleClose();
          } else {
            this.resetFlag1 = false;
            this.$message({
              showClose: true,
              message: res.data.data.resultInfo,
              type: "error",
              duration: 6000,
              // center: true,//居中
            });
          }
        })
        .catch(() => {
          this.resetFlag1 = false;
        });
    },

    // 1,,,,,,,,,,,,,,

    handleClose() {
      this.$emit("recovery");

      this.isShowAccount = true;
      this.top = true;
      this.changePass = false;
      this.resetFields();

      // 清空
      // this.form.Area = ""; //手机前缀
      this.form.name = ""; // 账号或者手机号
      this.form.code = ""; // 验证码
      this.form.password = ""; // 密码
      this.form.checkPass = ""; // 密码
    },
    // 验证账号 手机号
    verifyAccount() {
      // 还没接口（可能不需要验证了） 先写死
      const res = true;
      if (res) {
        // 存在跳
        this.reset();
      } else {
        // 不存在提示
        this.$message({
          showClose: true,
          message: "不存在。。。。",
          type: "error",
          duration: 6000,
          // center: true,//居中
        });
      }
    },
    /* 重置密码
       账号  点击第一个重置 获取密码策略
    * */
    // 重置
    reset() {
      // 获取密码策略
      this.getPasswordPolicy();
    },
    // 重置2
    reset2() {
      // 重置接口
      if (this.isShowArea) {
        // 手机号
        // 根据手机号和验证码判断是否可以修改密码
        this.isChangePasswordByVerificationCode();
      } else {
        // 账号
        // 根据邮件里的验证码判断是否可以修改密码
        this.isChangePasswordByEmail();
      }
    },
    // 获取验证码按钮
    obtain() {
      this.isShowObtain = false;

      if (this.isShowArea) {
        // 手机号
        // 向手机发送验证码，用于修改密码
        this.sendMessageForChangePassword();
      } else {
        // 账号
        // 发送邮件，用于修改密码
        this.sendEmailForChangePassword();
      }

      this.timer = setInterval(() => {
        this.count--;
        if (this.count == -1) {
          clearInterval(this.timer);
          this.isShowObtain = true;
          this.count = 60;
        }
      }, 1000);
    },
    // 表单验证
    //verificationCodePass  这是上一步验证码通过后返回的通过码
    //num 判断是什么时候的验证
    submitForm(formName, num, verificationCodePass) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (num === "1") {
            this.verifyAccount();
          } else if (num === "2") {
            // this.reset2();
          } else if (num === "3") {
            this.userPwModify(verificationCodePass);
          }
        } else {
          return false;
        }
      });
    },
    // 清除验证
    resetFields() {
      this.$refs.form.resetFields();
    },
    // 返回上一步
    previousStep() {
      this.resetFields();
      this.isShowAccount = true;
      this.top = true;
      this.changePass = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/*button动画效果*/
$button-width: 110px;
$spinner-width: $button-width / 6;
$btn-bg: #2c3248;
$text-light: #fff;
$font-size: 16px;
$margin: 0;
@import "../style/loadbutton";
.mr10 {
  margin-right: 20px;
}
::v-deep .el-dialog {
  width: 608px;
}
::v-deep .el-form-item__error {
  font-size: 16px !important;
  line-height: 1;
  padding-top: 4px;
  top: -12%;
  left: 15px;
  background: #fff;
  padding: 0px 7px;
  color: #f11b21;
}
::v-deep .el-form-item.is-error .el-input__inner {
  border: 1px solid #f11b21;
}
::v-deep .el-input__inner {
  /* 输入框 */
  font-size: 16px;
  border-radius: 4px 4px 4px 4px;
  height: 60px;
  /* border: none; */
  /* padding-left: 32px; */
}
::v-deep .el-dialog__header {
  text-align: center;

  .el-dialog__title {
    font-size: 24px;
    color: #333;
    font-weight: 500;
  }
  .el-dialog__close {
    font-size: 24px;
  }
}
.df {
  display: flex;
}
.top1 {
  padding-top: calc(50vh - 120px);
}
.top2 {
  padding-top: calc(50vh - 280px);
}
.formItem {
  position: relative;
  .delete1Btn {
    width: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .countDown {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #2d6cfc;
    font-size: 20px;
    .obtain1 {
      cursor: pointer;
    }
  }
  .invalid {
    background: #7b7c7f;
    width: 218px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 5px;
    right: 130px;
    color: #fff;
    border-radius: 2px;
    font-size: 24px;
  }
  .div300 {
    display: flex;
    width: 300px;
    justify-content: space-between;
    font-size: 18px;
  }
}
.account {
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .el-button {
      /*min-width: 100px;*/
      font-size: 16px;
    }
    .btn {
      background: #2c3248 !important;
      color: #fff;
    }
  }
}
.change {
  .formDiv {
    & > span {
      font-size: 16px;
      color: #f11b21;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .el-button {
      /*min-width: 100px;*/
      font-size: 16px;
    }
    .btn {
      background: #2c3248 !important;
      color: #fff;
    }
  }
}
@media screen and (max-width: 768px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
}
</style>
